/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  text-transform: uppercase;
}

.bg-hero-image {
  background-image: url("./images/hero-image.jpg");
  background-size: cover;
  background-position: 60%;
}

html {
  scroll-behavior: smooth;
  background: #f5f5f5;
}

body {
  background-image: url("./images/flower-bg.svg");
  background-repeat: repeat-y;
  background-size: contain;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  body {
    background-image: url("./images/flower-bg-mobile.svg");
    background-repeat: repeat-y;
    background-size: contain;
  }
}

.btn {
  @apply px-6 py-2 text-white bg-[#e63d68] rounded-md uppercase shadow-md hover:bg-[#cc365c] focus:outline-none focus:ring-2 focus:ring-[#e63d68] focus:ring-offset-1 transition duration-300 ease-in-out;
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
